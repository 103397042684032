import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import animation from "../../../../assets/img/animation/contact/4- Contact/Contact (1).gif";
import snap from "../../../../assets/img/Subtract.png";
import tiktok from "../../../../assets/img/Subtract1.png";
import twitter from "../../../../assets/img/Subtract2.png";
import insta from "../../../../assets/img/Subtract3.png";
import youtuop from "../../../../assets/img/Subtract4.png";
///////////
import snapyallow from "../../../../assets/img/Subtract (1).png";
import tiktokyallow from "../../../../assets/img/Subtract-1.png";
import twitteryallow from "../../../../assets/img/Subtract-2.png";
import instayallow from "../../../../assets/img/Subtract-3.png";
import youtuopyallow from "../../../../assets/img/Subtract-4.png";

import "aos/dist/aos.css";
import { Link } from "react-router-dom";

function ContactUs () {
  const { i18n, t } = useTranslation("home");
  const [hoverdImg, setHoverdImg] = useState({
    insta: false,
    snapchat: false,
    tiktok: false,
    youtuop: false,
    twitter: false
  });
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    AOS.refresh();
  }, [i18n.language]);


  const handleMouseOver = (SocialMedia) => {
    setHoverdImg((prev) => ({ ...prev, [SocialMedia]: true }))
  }
  const handleMouseLeave = (SocialMedia) => {
    setHoverdImg((prev) => ({ ...prev, [SocialMedia]: false }))
  }
  return (
    <>
      <div key={ i18n.language } className="w-100 headerImage pt-3">
        <div className="d-flex flex-row justify-end flex-wrap px-md-4 mx-3 mx-lg-5 my-5 pt-5 relative">
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            className={ `col-md-7 col-12 mt-md-0 mt-3 contact_gradient ${ i18n.language === "en"
                ? " md:pl-[13%] pl-5 pr-5 "
                : " md:pr-[13%] pr-5 pl-5"
              } py-lg-5 py-3 rounded-[10px] border border-white` }
          >
            <h1>{ t("contact_us.General_Inquiries") }</h1>
            <p>{ t("contact_us.General_Inquiries_Description") }</p>
            <br />
            <h1>{ t("contact_us.Careers_and_Job_Opportunities") }</h1>
            <p>{ t("contact_us.Careers_and_Job_Opportunities_Description") }</p>
            <br />
            <h1>{ t("contact_us.Customer_Support") }</h1>
            <p>{ t("contact_us.Customer_Support_Description") }</p>
            <br />
            <h1>{ t("contact_us.Projects_Collaborations_and_Partnerships") }</h1>
            <p>
              { t(
                "contact_us.Projects_Collaborations_and_Partnerships_Description"
              ) }
            </p>
            <br />
            <h1>{ t("contact_us.Media_and_Press_Inquiries") }</h1>
            <p>{ t("contact_us.Media_and_Press_Inquiries_Descrioption") }</p>
          </div>

          <div
            data-aos="fade-up" data-aos-duration="1000"
            className={ `col-md-6 col-12 mt-3 md:absolute order-md-0 bgimg bottom-20 border rounded-[10px] border-white ${ i18n.language === "en" ? "left-[28px]" : "right-[28px] "
              }` }
          >
            <div className="bg-[#000000cc] p-5 rounded-[10px]  ">
              <h1 className="text-yellow text-xl md:text-3xl text-center font-bold mt-5">
                { t("contact_us.title") }
              </h1>
              <p className="text-center my-3 my-md-5 md:text-lg">
                { t("contact_us.description") }
              </p>
              <div className="flex flex-row flex-wrap justify-center">
                <Link
                  to={
                    "https://www.youtube.com/channel/UCAYVrXuR9NFBjQ33v1G9ikg"
                  }
                >
                  <button
                    className="SocialMedia"
                    onMouseOver={ () => handleMouseOver("youtuop") }
                    onMouseLeave={ () => handleMouseLeave("youtuop") }
                  >
                    <img src={ hoverdImg.youtuop ? youtuopyallow : youtuop } alt="" />
                  </button>
                </Link>
                <Link to={ "https://www.instagram.com/luxuryksa/?hl=en" }>
                  <button
                    className="SocialMedia"
                    onMouseOver={ () => handleMouseOver("insta") }
                    onMouseLeave={ () => handleMouseLeave("insta") }
                  >
                    <img src={ hoverdImg.insta ? instayallow : insta } alt="" />
                  </button>
                </Link>
                <Link to={ "https://x.com/luxuryksa_" }>
                  <button
                    className="SocialMedia"
                    onMouseOver={ () => handleMouseOver("twitter") }
                    onMouseLeave={ () => handleMouseLeave("twitter") }
                  >
                    <img src={ hoverdImg.twitter ? twitteryallow : twitter } alt="" />
                  </button>
                </Link>
                <Link to={ "https://www.tiktok.com/@luxuryksa?lang=en" }>
                  <button
                    className="SocialMedia"
                    onMouseOver={ () => handleMouseOver("tiktok") }
                    onMouseLeave={ () => handleMouseLeave("tiktok") }
                  >
                    <img src={ hoverdImg.tiktok ? tiktokyallow : tiktok } alt="" />
                  </button>
                </Link>

              </div>
            </div>
          </div>
          <div className="absolute lg:w-[8%] lg:right-[94%] lg:top-[62%] md:w-[15%] md:right-[85%] md:top-[-20%] hide-on-tablet ">
            <img src={ animation } alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
